import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';

import { environment } from '../../environments/environment';
import { SubscriptionFormService } from '../subscription-steps/subscription-form.service';


@Injectable()
export class PasswordResetService extends SubscriptionFormService {
  lang: string;
  passwordResetForm: FormGroup;
  outerErrors: { [key: string]: string } = {};

  constructor(protected fb: FormBuilder,
              private http: HttpClient,
              private cookieService: CookieService) {
    super(fb);
    if (this.cookieService.get('defaultLocale')) {
      this.lang = this.cookieService.get('defaultLocale').split('-')[0];
    }
    this.passwordResetForm = this.fb.group({
      new_password1: new FormControl('',
        [this.NoWhitespaceValidator(), Validators.minLength(8), this.complexPasswordValidator(), this.OuterValidator('new_password1')]
      ),
      new_password2: new FormControl('',
        [this.NoWhitespaceValidator(), Validators.minLength(8), this.complexPasswordValidator(), this.OuterValidator('new_password2')]
      ),
      uid: new FormControl('',
        [this.OuterValidator('uid')]
      ),
      token: new FormControl('',
        [this.OuterValidator('token')]
      ),
      passwordEquality: ''
    }, {
      validator: this.extraValidator([
        ['new_password1', 'new_password2', 'passwordEquality']
      ])
    });
  }

  resetPassword(formData: { [key: string]: string }): Promise<any> {
    const lang = !!this.lang ? this.lang : 'fr';

    return this.http.post(
      `${environment.API_URL}/${lang}/api/v1/auth/password/reset/confirm/`,
      formData,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }
        )
      }
    ).toPromise();
  }
}
