import { NgModule, ApplicationRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { removeNgStyles, createNewHosts } from '@angularclass/hmr';
import { TranslationModule, L10nConfig, L10nLoader, ProviderType } from 'angular-l10n';
import { MdlSelectModule } from '@angular-mdl/select';
import { MdlPopoverModule } from '@angular-mdl/popover';
import { MdlModule } from '@angular-mdl/core';
import { MomentModule } from 'ngx-moment';
import { ChartModule } from 'angular2-highcharts';
import { TagCloudModule } from 'angular-tag-cloud-module';
import { AgmCoreModule } from '@agm/core';
import { HeaderModule } from './header/header.module';

import { routing } from './app.routing';
import { SubscriptionFormValuesService } from './subscription-steps/subscription-form-values.service';
import { RegistrationService } from './subscription-steps/registration.service';
import {
  SearchService,
  SharedDataService,
  AccountsService,
  AuthService,
  DeviceService,
  FavoritesService,
  HeaderService,
  DefaultService,
  QuickSearchService
} from './shared';

import { GoogleAnalyticsEventsService } from './google-analytics-events.service';

import { ValidFormGuard } from './subscription-steps/valid-form.guard';
import { NavigateOutsideFormGuard } from './subscription-steps/navigate-outside-form.guard';
import { AuthGuard } from './member-zone/auth.guard';
import { ManagerGuard } from './shared/manager.guard';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { FooterComponent } from './footer/footer.component';
import { ContactComponent } from './contact/contact.component';
import { ServicesComponent } from './services/services.component';
import { TopComponent } from './top/top.component';
import { SubscriptionStepsComponent } from './subscription-steps/subscription-steps.component';
import { StepBarComponent } from './subscription-steps/step-bar/step-bar.component';
import { ButtonsBarComponent } from './subscription-steps/buttons-bar/buttons-bar.component';
import { SubscriptionStep1Component } from './subscription-steps/subscription-step-1/subscription-step-1.component';
import { SubscriptionStep2Component } from './subscription-steps/subscription-step-2/subscription-step-2.component';
import { SubscriptionStep3Component } from './subscription-steps/subscription-step-3/subscription-step-3.component';
import { SubscriptionStep4Component } from './subscription-steps/subscription-step-4/subscription-step-4.component';
import { SubscriptionStep5Component } from './subscription-steps/subscription-step-5/subscription-step-5.component';
import { CommonComponentsModule, CommonDirectivesModule, CitiesComponent } from './shared';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { NotificationsService } from './shared/notifications.service';
import { AdminLayoutComponent } from './admin/admin-layout/admin-layout.component';
import { AdminHomeComponent } from './admin/admin-home/admin-home.component';
import { AdminNavComponent } from './admin/admin-nav/admin-nav.component';
import { AdminSubscriptionComponent } from './admin/subscription/admin-subscription/admin-subscription.component';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { IsAdminGuard } from './admin/is-admin.guard';
import { AdminSubscriptionListComponent } from './admin/subscription/admin-subscription-list/admin-subscription-list.component';
import { AdminSubscriptionDetailComponent } from './admin/subscription/admin-subscription-detail/admin-subscription-detail.component';
import { AdminSubscriptionCreateComponent } from './admin/subscription/admin-subscription-create/admin-subscription-create.component';
import { MyTableComponent } from './shared/my-table/my-table.component';
import { MyModalService } from './shared/my-modal/my-modal.service';
import { MyModalComponent } from './shared/my-modal/my-modal.component';
import { SubscriptionService } from './services/subscription.service';
import { InfluencersService } from './member-zone/influencers/services/influencers.service';
import { InfluencerCategoriesService } from './member-zone/influencers/services/influencer-categories.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { PaymentService } from './services/payment.service';
import { CurrencyPipe } from '@angular/common';
import { AlertComponent } from './shared/component/alert/alert.component';
import { ProvincesService } from './member-zone/influencers/services/provinces.service';
import { InfluencerLanguagesService } from './member-zone/influencers/services/influencer-languages.service';
import { CalendarIconComponent } from './shared/calendar-icon/calendar-icon.component';
import { BankCheckIconComponent } from './shared/bank-check-icon/bank-check-icon.component';
import { CookieModule } from 'ngx-cookie';
import { LoginPageComponent } from './login-page/login-page.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {Error401Interceptor} from './shared/interceptors/error401.interceptor';

const l10nConfig: L10nConfig = {
  locale: {
    languages: [
      {
        code: 'fr',
        dir: 'ltr',
      },
      {
        code: 'en',
        dir: 'ltr',
      },
    ],
    language: 'fr',
    defaultLocale: {
      languageCode: 'fr',
      countryCode: 'ca',
    },
    currency: 'CAD',
  },
  translation: {
    providers: [
      { type: ProviderType.Static, prefix: '../assets/localization/locale-' }
    ]
  }
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslationModule.forRoot(l10nConfig),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MdlSelectModule,
    MdlPopoverModule,
    MdlModule,
    HeaderModule,
    CommonComponentsModule,
    MomentModule,
    TagCloudModule,
    CommonDirectivesModule,
    ChartModule.forRoot(require('highcharts')),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBzJQAxwx01SNHlznp-igZHtmwpzRhx1Mk',
      libraries: ['places'],
      language: 'en-US'
    }),
    routing,
    CookieModule.forRoot(),
    MatDialogModule,
    MatIconModule,
    BrowserModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatButtonModule
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    FooterComponent,
    ContactComponent,
    ServicesComponent,
    TopComponent,
    SubscriptionStepsComponent,
    StepBarComponent,
    ButtonsBarComponent,
    SubscriptionStep1Component,
    SubscriptionStep2Component,
    SubscriptionStep3Component,
    SubscriptionStep4Component,
    SubscriptionStep5Component,
    CitiesComponent,
    PageNotFoundComponent,
    PasswordResetComponent,
    AdminLayoutComponent,
    AdminHomeComponent,
    AdminNavComponent,
    AdminSubscriptionComponent,
    DefaultLayoutComponent,
    AdminSubscriptionListComponent,
    AdminSubscriptionDetailComponent,
    AdminSubscriptionCreateComponent,
    MyTableComponent,
    AlertComponent,
    CalendarIconComponent,
    BankCheckIconComponent,
    LoginPageComponent,
  ],
  providers: [
    SubscriptionFormValuesService,
    HeaderService,
    RegistrationService,
    ValidFormGuard,
    NavigateOutsideFormGuard,
    AuthGuard,
    ManagerGuard,
    DefaultService,
    SearchService,
    AccountsService,
    SharedDataService,
    AuthService,
    DeviceService,
    FavoritesService,
    NotificationsService,
    QuickSearchService,
    GoogleAnalyticsEventsService,
    IsAdminGuard,
    MyModalService,
    SubscriptionService,
    PaymentService,
    InfluencersService,
    InfluencerCategoriesService,
    CurrencyPipe,
    ProvincesService,
    InfluencerLanguagesService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Error401Interceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(public appRef: ApplicationRef, public l10nLoader: L10nLoader) {
    this.l10nLoader.load();
  }

  hmrOnInit(store) {
    console.log('HMR store', store);
  }

  hmrOnDestroy(store) {
    let cmpLocation = this.appRef.components.map(cmp => cmp.location.nativeElement);
    // recreate elements
    store.disposeOldHosts = createNewHosts(cmpLocation);
    // remove styles
    removeNgStyles();
  }

  hmrAfterDestroy(store) {
    // display new elements
    store.disposeOldHosts();
    delete store.disposeOldHosts;
  }
}
