import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SubscriptionService } from '../../../services/subscription.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MyModalService } from '../../../shared/my-modal/my-modal.service';
import { PaymentService } from '../../../services/payment.service';
import {AccountsService, Member, Subscription} from '../../../shared';
import { CurrencyPipe } from '@angular/common';
import { Manager } from '../../../shared/model/manager';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { Language } from 'angular-l10n';
import * as _ from 'underscore';

interface Product {
  catalogue?: any;
  plan?: any;
  old_quantity: number;
  new_quantity: number;
}

@Component({
  selector: 'blitz-admin-subscription-detail',
  templateUrl: './admin-subscription-detail.component.html',
  styleUrls: ['./admin-subscription-detail.component.scss']
})
export class AdminSubscriptionDetailComponent implements OnInit {

  @Language() lang: string;
  @ViewChild('stripeErrorEl') stripeErrorEl: ElementRef;

  addons = [];
  subscription: Subscription = null;
  waitAPI = false;
  temporaryDaysBeforeDue = null;
  temporaryPrice = null;
  temporaryPaymentType = null;
  alertPrice = ['Attention, modifier le prix d\'une subscription n\'affecte pas le contenu de la subscription (détails des add ons) et ' +
  'ne modifie pas la facture (il sera peut être nécessaire de la regénérer manuellement).'];
  alertCC = ['Attention, changer le prix de ce paiement par carte de crédit qui n\'est pas encore approuvé changera le montant total ' +
  'facturé.'];
  errors = null;

  products: Product[] = [];

  subscriptionManager: Manager;

  settings = {
    noDataText: 'Aucun addon',
    columns: [
      {
        name: 'title',
        title: 'Nom du produit'
      },
      {
        name: 'quantity',
        title: 'Quantité'
      },
      {
        name: 'price',
        title: 'Prix unitaire HT'
      }
    ]
  };

  settings_request = {
    noDataText: 'Aucun addon',
    columns: [
      {
        name: 'title',
        title: 'Nom du produit'
      },
      {
        name: 'quantity',
        title: 'Quantité'
      },
      {
        name: 'old_quantity',
        title: 'Ancienne Quantité'
      },
      {
        name: 'price',
        title: 'Prix unitaire HT'
      },
      {
        name: 'total',
        title: 'Prix HT total'
      }
    ]
  };

  settings_members = {
    noDataText: 'Aucun membre',
    columns: [
      {
        name: 'is_active',
        type: 'boolean',
        title: 'Activé'
      },
      {
        name: 'first_name',
        title: 'Prénom'
      },
      {
        name: 'last_name',
        title: 'Nom de famille'
      },
      {
        name: 'email',
        title: 'Courriel'
      }
    ]
  };

  ccYears: number[];
  ccMonths = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  ccForm: FormGroup;
  stripeError: string;

  members: Member[];


  chargeCardValidationModal = false;
  defaultCard: any;

  constructor(private subscriptionService: SubscriptionService,
              private paymentService: PaymentService,
              private myModalService: MyModalService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private currency: CurrencyPipe,
              private accountService: AccountsService,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.addStripeScript();

    this.activatedRoute.params.subscribe((params: Params) => {
      this.refreshSubscription(params['id']);
      this.getMembers(params['id']);
    });

    const date = new Date().getFullYear();
    this.ccYears = _.range(date, date + 6);

    this.createCCForm();
  }

  getMembers(subscriptionId) {
    this.subscriptionService.listMembers(subscriptionId).subscribe(
      (response) => {
        this.members = response;
      }
    );
  }

  refreshSubscription(id = null) {
    let index = null;
    if (id) {
      index = id;
    } else if (this.subscription) {
      index = this.subscription.id;
    }

    if (index) {
      this.getSubscription(index);
      this.getProducts(index);
    }
  }

  getDefaultCard() {
    this.paymentService.getDefaultCard(this.subscriptionManager.id).subscribe(
      data => {
        this.defaultCard = data['default_card'];
      }
    );
  }

  getProducts(id: number) {
    this.subscriptionService.compare(id).subscribe(
      data => {
        this.products = data.products.map(a => this.formatProduct(a));
      }
    );
  }

  getSubscription(id: number) {
    this.subscriptionService.get(id).subscribe(
      data => {
        this.subscription = data;
        console.log(data);
        this.addons = this.subscription.addons.map(a => this.formatAddon(a));
        this.addons.push(
          {
            title: 'Abonnement: ' + this.subscription.catalogue.plan.title,
            price: this.currency.transform(this.subscription.catalogue.price, 'CAD'),
            quantity: 1,
          }
        );
        this.subscriptionManager = this.subscription.manager as Manager;
        if (this.subscription.payment_type === 'cc') {
          this.getDefaultCard();
        }
      }
    );
  }

  formatDay(date, addDays = 0) {
    if (!date) {
      return '-';
    }
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };
    const final_date = new Date(date);
    final_date.setDate(final_date.getDate() + addDays);
    return final_date.toLocaleDateString('fr', options);
  }

  public formatAddon(addon) {
    return {
      title: addon.plan.title,
      price: this.currency.transform(addon.plan.price, 'CAD'),
      quantity: addon.amount,
    };
  }

  public formatProduct(product: Product) {

    const diffQuantity = product.new_quantity - product.old_quantity;

    const diffQuantityFormat = diffQuantity === 0 ? '' : `(${diffQuantity})`;

    const plan = product.catalogue ? product.catalogue.plan : product.plan;
    const price = product.catalogue ? product.catalogue.price : product.plan.price;

    return {
      title: plan.title,
      quantity: `${product.new_quantity} ${diffQuantityFormat}`,
      old_quantity: `${product.old_quantity}`,
      price: this.currency.transform(price, 'CAD'),
      total: this.currency.transform(product.new_quantity * price, 'CAD')
    };
  }

  isSubscriptionApprove(): boolean {

    return this.subscription.is_approved;
  }

  public approvePayment(force = false) {
    if (!force) {
      const modal = this.myModalService.get('approve_payment');
      modal.toggle();
    } else {
      this.paymentService.approve(this.subscription.payment.id).subscribe(
        value => {
          this.getSubscription(this.subscription.id);
        }
      );
    }
  }

  public approveSubscription(force = false) {
    if (!force) {
      const modal = this.myModalService.get('approve_subscription');
      modal.toggle();
    } else {
      this.waitAPI = true;
      this.subscriptionService.approve(this.subscription.id).subscribe(
        value => {
          this.waitAPI = false;
          this.getSubscription(this.subscription.id);
        },
        error => {
          this.errors = error['non_field_errors'];
          this.myModalService.get('error_modale').toggle();
        }
      );
    }
  }

  public deleteSubscription(force = false) {
    if (!force) {
      const modal = this.myModalService.get('delete_subscription');
      modal.toggle();
    } else {
      this.waitAPI = true;
      this.subscriptionService.delete(this.subscription.id).subscribe(
        value => {
          this.waitAPI = false;
          this.router.navigate(['/admin/subscription']);
        }
      );
    }
  }

  public editPayment(force = false) {
    if (!force) {
      this.temporaryDaysBeforeDue = this.subscription.payment.days_before_due;
      this.temporaryPrice = this.subscription.payment.price;
      this.temporaryPaymentType = this.subscription.payment_type;
      const modal = this.myModalService.get('edit_payment');
      modal.toggle();
    } else {
      this.subscription.payment.days_before_due = this.temporaryDaysBeforeDue;
      this.subscription.payment.price = this.temporaryPrice;
      this.paymentService.patch(this.subscription.payment).subscribe();

      if ( this.subscription.payment_type !== this.temporaryPaymentType) {
        this.subscriptionService.update(
          this.subscription.id,
          { payment_type: this.temporaryPaymentType })
          .subscribe(
            (subscription: Subscription) => this.subscription = subscription
          );
      }
    }
  }

  public hasAlertCC() {
    return this.subscription.payment_type === 'cc' &&
      this.subscription.is_approved === false &&
      this.subscription.payment.price !== this.temporaryPrice;
  }

  public hasButtonApprovePayment() {
    return !this.subscription.payment.completed &&
      this.subscription.is_approved &&
      this.subscription.payment_type === 'bill';
  }

  public hasButtonChargeCard() {
    return !this.subscription.payment.completed &&
      this.subscription.is_approved &&
      this.subscription.payment_type === 'cc' &&
      !this.subscription.payment.card_is_charged &&
      this.defaultCard;
  }

  updateManager() {
    const dataToUpdate = {
      comment: this.subscriptionManager.comment
    };

    this.accountService.updateMember(
      this.subscriptionManager.id,
      true,
      dataToUpdate
    ).subscribe(
      (manager: Manager) => {
        this.subscriptionManager = manager;
      }
    );
  }

  openCardModal() {
    const modal = this.myModalService.get('change_manager_card');
    modal.toggle();
  }

  chargeCardModal() {
    const modal = this.myModalService.get('charge_card');
    modal.toggle();
  }

  createCCForm() {

    this.ccForm = this.fb.group({
      cc_number: new FormControl('', [Validators.pattern(/^[0-9]+$/)]),
      cc_cvc: new FormControl('', [Validators.pattern(/^[0-9]+$/)]),
      cc_exp_month: new FormControl('', [Validators.required]),
      cc_exp_year: new FormControl('', [Validators.required])
    });
  }

  private hasStripeError(status: number, response: any): boolean {
    if (status === 200) {
      return false;
    }
    this.stripeErrorEl.nativeElement.innerText = response.error.message;
    return true;
  }


  private addStripeScript() {
    const stripeScript = document.createElement('script');
    stripeScript.setAttribute('src', 'https://js.stripe.com/v2/');
    document.head.appendChild(stripeScript);
  }

  updateManagerCard() {
    if ((<any>window).Stripe) {
      (<any>window).Stripe.setPublishableKey(environment.STRIPE_PK);
    }
    (<any>window).Stripe.card.createToken({
      number: this.ccForm.controls.cc_number.value,
      cvc: this.ccForm.controls.cc_cvc.value,
      exp_month: parseFloat(this.ccForm.controls.cc_exp_month.value),
      exp_year: parseFloat(this.ccForm.controls.cc_exp_year.value)
    }, (status: number, response: any) => {
      if (this.hasStripeError(status, response)) {
        return;
      }
      this.subscriptionService.updateManagerCard(this.subscription.id, response.id)
        .subscribe(
          () => {
            this.myModalService.get('change_manager_card').close();
            this.ccForm.reset();
            this.getDefaultCard();
          }
        );
    });
  }

  /**
   * Try to charge the card of the manager in order to complete the payment
   */
  chargeCard() {
    this.waitAPI = true;

    this.paymentService.chargeCard(this.subscription.payment).subscribe(
      data => {
        this.refreshSubscription();
        this.waitAPI = false;
      }
    );
  }

  get labelForUpdateCardButton() {
    if (this.defaultCard) {
      return 'Modifier la carte de paiment';
    } else {
      return 'Ajouter une carte de paiment';
    }
  }

  getDeleteSubscriptionLabel() {
    if (!this.subscription.is_approved) {
      return 'Refuser l\'abonnement';
    } else {
      return 'Supprimer l\'abonnement';
    }
  }
}
