import { Injectable } from '@angular/core';
import { FormGroup, Validators, FormControl, FormBuilder, ValidatorFn, AbstractControl } from '@angular/forms';

import { DefaultFormService } from '../shared';

@Injectable()
export class SubscriptionFormService extends DefaultFormService {
  userForm: FormGroup;
  afterSubmitValues = {};
  outerErrors = {};

  constructor(protected fb: FormBuilder) {
    super();
    this.userForm = this.fb.group({
      last_name: new FormControl('', [this.NoWhitespaceValidator(), this.OuterValidator('last_name')]),
      first_name: new FormControl('', [this.NoWhitespaceValidator(), this.OuterValidator('first_name')]),
      email: new FormControl('', [this.NoWhitespaceValidator(), this.emailValidator(), this.OuterValidator('email')]),
      email_confirm: new FormControl('', [this.NoWhitespaceValidator(), this.emailValidator(), this.OuterValidator('email_confirm')]),
      emailEquality: '',
      password: new FormControl('',
        [this.NoWhitespaceValidator(), Validators.minLength(8), this.complexPasswordValidator(), this.OuterValidator('password')]
      ),
      password_confirm: new FormControl('',
        [this.NoWhitespaceValidator(), Validators.minLength(8), this.complexPasswordValidator(), this.OuterValidator('password_confirm')]
      ),
      passwordEquality: '',
      civility: new FormControl('', [Validators.required, this.OuterValidator('civility')]),
      addons: new FormControl([], []),
      subscription_plan: new FormControl('', []),
      address: new FormControl('', [this.NoWhitespaceValidator(), this.OuterValidator('address')]),
      country: new FormControl('', [Validators.required, this.OuterValidator('country')]),
      region: new FormControl({value: '', disabled: true}, [Validators.required, this.noCountry(), this.OuterValidator('region')]),
      city: new FormControl({value: '', disabled: true}, [Validators.required, this.noCountryOrProvince(), this.OuterValidator('city')]),
      postcode: new FormControl('', [this.NoWhitespaceValidator(), this.OuterValidator('postcode')]),
      phone: new FormControl('', [this.NoWhitespaceValidator(), this.OuterValidator('phone')]),
      company: new FormControl('', [this.NoWhitespaceValidator(), this.OuterValidator('company')]),
      accounting_email: new FormControl('', [this.emailValidator(), this.OuterValidator('accounting_email')]),
      tos_agreement: new FormControl(false),
      newsletter_agreement: new FormControl(false),
      comment: new FormControl('', [this.OuterValidator('comment')])
    }, {
      validator: this.extraValidator([
        ['email', 'email_confirm', 'emailEquality'],
        ['password', 'password_confirm', 'passwordEquality']
      ])
    });
  }

  protected extraValidator(fields: any) {
    return this.equalityValidator(fields);
  }

  protected equalityValidator(fields: any) {
    return (group: FormGroup) => {
      fields.forEach(field => {
        let input = group.controls[field[0]],
          confirmationInput = group.controls[field[1]],
          errorInput = group.controls[field[2]];
        if (input.value !== confirmationInput.value && !input.pristine && !confirmationInput.pristine &&
          !confirmationInput.getError('required')) {
          errorInput.setErrors({ 'notEquivalent': true });
        } else {
          errorInput.setErrors(null);
        }
      });
    };
  }

  protected equalityPass(fields: any) {
    return (group: FormGroup) => {
      fields.forEach(field => {
        let input = group.controls[field[0]],
          confirmationInput = group.controls[field[1]];
        if (input.value !== confirmationInput.value && !input.pristine && !confirmationInput.pristine &&
          !confirmationInput.getError('required')) {
          confirmationInput.setErrors({ 'notEquivalent': true });
        } else {
          confirmationInput.setErrors(null);
        }
      });
    };
  }

  protected complexPasswordValidator(): ValidatorFn {
    let regExpPass = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;
    return (control: AbstractControl): { [key: string]: any } => {
      const strong = regExpPass.test(control.value);
      return strong ? null : { 'weakPassword': true };
    };
  }

  protected emailValidator(): ValidatorFn {
    // Use regex from https://emailregex.com/
    // tslint:disable-next-line:max-line-length
    let regExpPass = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (control: AbstractControl): { [key: string]: any } => {
      const strong = regExpPass.test(control.value);
      return strong ? null : { 'email': true };
    };
  }

  protected noCountry(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.parent && control.parent.controls && !control.parent.controls['country'].value) {
        return { 'noCountry': true };
      }
      return null;
    };
  }

  protected noCountryOrProvince(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.parent && control.parent.controls &&
         (!control.parent.controls['country'].value || !control.parent.controls['region'].value)) {
        return { 'noCountryOrProvince': true };
      }
      return null;
    };
  }
}
