import { Component, Input, OnInit } from '@angular/core';
import { Language } from 'angular-l10n';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'blitz-validation-messages',
  templateUrl: './validation-messages.component.html',
  styleUrls: ['./validation-messages.component.scss']
})
export class ValidationMessagesComponent implements OnInit {
  @Language() lang: string;
  @Input() form: FormGroup;
  @Input() path: string;
  @Input() isValid: boolean;
  notDefaultFields: string[];

  constructor() {
    this.notDefaultFields = [
      'password_new', 'password_confirm', 'password_old', 'email', 'first_name', 'last_name', 'postcode',
      'phone', 'address', 'company', 'country', 'region', 'city', 'accounting_email'
    ];
  }

  ngOnInit() {
  }

  public hasError() {
    return this.form && this.form.get(this.path) && (this.form.get(this.path).touched || this.isValid);
  }
}
