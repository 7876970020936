import * as _ from 'underscore';
import { Injectable } from '@angular/core';
import { FormControl, ValidatorFn, AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export abstract class DefaultFormService {
  afterSubmitValues = {};
  outerErrors = {};

  protected constructor() { }

  OuterValidator(controlName: string): ValidatorFn {
    return (control: FormControl): { [key: string]: any } => {
      if (control.value && control.value === this.afterSubmitValues[controlName] && !_.isUndefined(this.outerErrors[controlName])) {
        return { 'outerError': true };
      }
      return null;
    };
  }

  NoWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let empty = (control.value || '').toString().trim().length === 0;
      return empty ? { 'required': true } : null;
    };
  }
}
