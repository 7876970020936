import { Injectable } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';

import { DefaultFormService } from '../../shared';

@Injectable()
export class LoginService extends DefaultFormService {
  blitzForm: FormGroup;
  blitzResetPassForm: FormGroup;

  constructor(protected fb: FormBuilder) {
    super();
    this.blitzForm = this.fb.group({
      email: new FormControl('', [this.NoWhitespaceValidator(), this.OuterValidator('email')]),
      password: new FormControl('', [this.NoWhitespaceValidator(), this.OuterValidator('password')]),
    });
    this.blitzResetPassForm = this.fb.group({
      email: new FormControl('', [this.NoWhitespaceValidator(), this.OuterValidator('email')])
    });
  }
}
