import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Language, TranslationService } from 'angular-l10n';

import { LoginService } from './login.service';
import { AuthService } from '../../shared';
import { HeaderService, SearchService } from '../../shared/index';

@Component({
  selector: 'blitz-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @Language() public lang: string;
  @Input() public showModal: boolean;
  @Input() public showPage: boolean;
  @Input() public navInputLink: string;
  @Input() public displayPage: boolean = false;
  @Output() public close: EventEmitter<boolean> = new EventEmitter();
  public emailSent: boolean;
  public loginForm: FormGroup;
  public resetPassForm: FormGroup;
  public showResetPassForm: boolean;
  public showResendEmail: boolean;
  public disableLoginBtn: boolean;
  public disableResetBtn: boolean;
  public disableResendBtn: boolean;
  public disableResendBtnDuration: number;
  public errorMsg: {[key: string]: string};

  constructor(public loginService: LoginService,
              private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private headerService: HeaderService,
              private searchService: SearchService,
              private translation: TranslationService) {
    this.loginForm = this.loginService.blitzForm;
    this.resetPassForm = this.loginService.blitzResetPassForm;
    this.errorMsg = {
      incorrectLog: this.translation.translate('Login.Incorrect Log'),
      incorrectEmail: this.translation.translate('Login.Incorrect Email')
    };
  }

  ngOnInit() {
  }

  onClose() {
    this.showResetPassForm = false;
    this.close.emit(false);
  }

  public toggleResetPass() {
    this.emailSent = false;
    this.showResendEmail = false;
    this.showResetPassForm = !this.showResetPassForm;
  }

  public toggleResendEmail() {
    this.showResendEmail = !this.showResendEmail;
  }

  public onSubmitLoginForm() {
    if (this.loginForm.valid) {
      this.disableLoginBtn = true;
      console.log('will send login');
      this.authService.login(this.loginForm.value.email, this.loginForm.value.password).subscribe(
        value => {
          localStorage.clear();
          sessionStorage.clear();
          const token = value.token;
          localStorage.setItem('currentUser', JSON.stringify({email: this.loginForm.value.email, token: token}));

          this.disableLoginBtn = false;
          this.onClose();
          this.searchService.updateHeaders();
          this.authService.getProfile().subscribe(
            (data) => {
              this.authService.setFullUserInfo(data);
              this.headerService.setShowLoginModal(false);
              const { returnUrl } = this.route.snapshot.queryParams;
              if (this.navInputLink) {
                this.router.navigateByUrl(this.navInputLink);
              } else if (returnUrl) {
                this.router.navigateByUrl(returnUrl);
              } else {
                this.router.navigateByUrl('/mz/dashboard');
              }
            }
          );
        },
        err => {
          this.disableLoginBtn = false;
          this.loginForm.setErrors(err.error);
        }
      );
    } else {
      this.loginForm.controls.email.markAsTouched();
      this.loginForm.controls.password.markAsTouched();
      return;
    }
  }
  public onSubmitResetForm() {
    if (this.resetPassForm.valid) {
      this.disableResetBtn = true;
      this.authService.resetPassword(this.resetPassForm.value.email.toLowerCase()).subscribe(
        value => {
          this.disableResetBtn = false;
          if (value && value.email) {
            this.resetPassForm.setErrors({ 'incorrectEmail': true });
            return;
          }
          const waitTime = 120000;
          this.disableResendBtnDuration = waitTime / 60000;
          this.emailSent = true;
          this.disableResendBtn = true;
          setTimeout(() => {
            this.disableResendBtn = false;
          }, waitTime);
        },
        err => {
          this.disableResetBtn = false;
          this.resetPassForm.setErrors(err.error.detail);
        }
      );
    } else {
      this.resetPassForm.controls.email.markAsTouched();
      return;
    }
  }

}
